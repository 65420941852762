import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';

import { MobiUiIconComponent, MobiUiIconsRegistry } from '@mobi/rwc-ui-components-ng-jslib';
import { mobiIconMinus, mobiIconPlus } from '@mobi/rwc-utils-icons-jslib';
import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';

@Component({
    selector: 'oiv-viewer-global-toggle-collapse-all-button',
    standalone: true,
    imports: [MobiUiIconComponent, TranslatePipe],
    templateUrl: './global-toggle-collapse-all-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrl: './global-toggle-collapse-all-button.component.scss',
})
export class GlobalToggleCollapseAllButtonComponent {
    shouldExpandAll = input(true);

    // Broadcasts the event to toggle the expanded state of all expandable components
    onToggleExpanded = output<boolean>();
    #mobiUIIconsRegistry = inject(MobiUiIconsRegistry);

    constructor() {
        this.#mobiUIIconsRegistry.registerIcons([mobiIconPlus, mobiIconMinus]);
    }

    // This toggle will emit the value that passed from outside
    // due to the action of button will lead the outsider going work with following direction.
    // The value changes will be adjusted by the parent components
    toggleCollapseAll() {
        this.onToggleExpanded.emit(this.shouldExpandAll());
    }
}
