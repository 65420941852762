<div class="mobi-ui-flow mobi-ui-end mobi-ui-margin-t-xxxl">
    <button type="button" class="mobi-ui-btn-minor" (click)="toggleCollapseAll()">
        @if (shouldExpandAll()) {
            {{ 'oiv.title.expandAll' | translate }}
            <mobi-ui-icon-major name="plus" class="mobi-ui-animation-rotate-45-init md"></mobi-ui-icon-major>
        } @else {
            {{ 'oiv.title.collapseAll' | translate }}
            <mobi-ui-icon-major
                name="plus"
                class="mobi-ui-animation-rotate-45-init md mobi-ui-animation-rotate-45-active"
            ></mobi-ui-icon-major>
        }
    </button>
</div>
