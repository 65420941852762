import { ChangeDetectionStrategy, Component, forwardRef, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Content } from '@mobi/oiv-viewer-utils-ng-jslib';

import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { getParagraphCss } from '../../../util/get-css-style';
import { BulletListComponent } from '../bullet-list/bullet-list.component';
import { ParagraphComponent } from '../paragraph/paragraph.component';

import {
    ALPHA_NUM_LOWER_LIST_TYPE,
    ALPHA_NUM_UPPER_LIST_TYPE,
    NUMBER_PATTERN,
    NUMERIC_LIST_TYPE,
    OIV_ALPHA_NUM_LOWER_LIST_TYPE,
    OIV_ALPHA_NUM_UPPER_LIST_TYPE,
    OIV_NUMERAL_LIST_TYPE,
    OIV_ROMAN_NUM_LOWER_LIST_TYPE,
    OIV_ROMAN_NUM_UPPER_LIST_TYPE,
    ROMAN_NUM_LOWER_LIST_TYPE,
    ROMAN_NUM_UPPER_LIST_TYPE,
} from '../../../util/constant/common';
import { Helper } from '../../helper';
import { BoxComponent } from '../box/box.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'oiv-viewer-numbered-list',
    standalone: true,
    imports: [CommonModule, forwardRef(() => BulletListComponent), ParagraphComponent, forwardRef(() => BoxComponent)],
    templateUrl: './numbered-list.component.html',
    styleUrl: './numbered-list.component.scss',
})
export class NumberedListComponent {
    readonly helper = inject(Helper);

    @Input() items: Content[] = [];
    @Input() eId = '';
    @Input() listType = '';

    protected readonly getParagraphCss = getParagraphCss;
    protected readonly OIV_TYPE = OIV_TYPE;

    protected listTypeMap: { [key: string]: { listType: string; type: string } } = {
        [NUMERIC_LIST_TYPE]: { listType: OIV_NUMERAL_LIST_TYPE, type: '1' },
        [ALPHA_NUM_LOWER_LIST_TYPE]: { listType: OIV_ALPHA_NUM_LOWER_LIST_TYPE, type: 'a' },
        [ALPHA_NUM_UPPER_LIST_TYPE]: { listType: OIV_ALPHA_NUM_UPPER_LIST_TYPE, type: 'A' },
        [ROMAN_NUM_LOWER_LIST_TYPE]: { listType: OIV_ROMAN_NUM_LOWER_LIST_TYPE, type: 'i' },
        [ROMAN_NUM_UPPER_LIST_TYPE]: { listType: OIV_ROMAN_NUM_UPPER_LIST_TYPE, type: 'I' },
    };

    protected getListType(type: string): string {
        return this.listTypeMap[type]?.listType || OIV_ALPHA_NUM_LOWER_LIST_TYPE;
    }

    protected getType(type: string): string {
        return this.listTypeMap[type]?.type || 'a';
    }

    /**
     * Get the starting order number for a list item.
     *
     * @param sequence - The sequence string of the list item.
     *
     * - If the sequence is an empty string, the function returns 1 (the default case).
     * - If the sequence is a numeric string, the function parses it to an integer and returns it.
     * - If the sequence is a letter, the function returns the order number of the letter in the alphabet (a=1, b=2, c=3, etc.)
     *
     * @returns The starting order number for the list items. This supports for the start attribute of the ol tag.
     */
    getStartOrderNumber(sequence: string): number {
        if (sequence === '') {
            return 1;
        }
        if (NUMBER_PATTERN.test(sequence)) {
            return parseInt(sequence);
        } else {
            return this.helper.getAlphabetIndex(sequence);
        }
    }
}
