export const OIV_BASE_URL = '/oiv/viewer';
export const OIV_QA_BASE_URL = '/oiv/questionanswering';
export const MCM_BASE_URL = '/mcm/information';
export const SEARCH_PAGE_SIZE = 10;
export const NUMERIC_LIST_TYPE = 'numeric';
export const ALPHA_NUM_UPPER_LIST_TYPE = 'alpha-num-upper-case';
export const ALPHA_NUM_LOWER_LIST_TYPE = 'alpha-num-lower-case';
export const ROMAN_NUM_LOWER_LIST_TYPE = 'roman-num-lower-case';
export const ROMAN_NUM_UPPER_LIST_TYPE = 'roman-num-upper-case';

export const OIV_ALPHA_NUM_UPPER_LIST_TYPE = 'oiv-alpha-upper-list-type';
export const OIV_ROMAN_NUM_LOWER_LIST_TYPE = 'oiv-roman-lower-list-type';
export const OIV_ROMAN_NUM_UPPER_LIST_TYPE = 'oiv-roman-upper-list-type';
export const OIV_ALPHA_NUM_LOWER_LIST_TYPE = 'oiv-alpha-lower-list-type';
export const OIV_NUMERAL_LIST_TYPE = 'oiv-numeral-list-type';

export const NUMBER_PATTERN = /^\d+$/;
export const INDEX_SEARCH = 'index-search';
export const NAVIGATION = 'navigation';
export const AI_SEARCH = 'ai-search';
export const FEEDBACK_TYPE_POSITIVE = 'positive';
export const FEEDBACK_TYPE_NEGATIVE = 'negative';
export const PRODUCT_URL = 'https://b2e.oiv.mobiliar.ch';
export const PRE_PRODUCT_URL = 'https://b2e.oiv.app.mobiliar-preprod.ch';
