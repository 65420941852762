import { ChangeDetectionStrategy, Component, forwardRef, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Content } from '@mobi/oiv-viewer-utils-ng-jslib';

import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { ParagraphComponent } from '../paragraph/paragraph.component';

import { HeadingComponent } from '../heading/heading.component';

import { getParagraphCss } from '../../../util/get-css-style';
import { Helper } from '../../helper';
import { BoxComponent } from '../box/box.component';
import { NumberedListComponent } from '../numbered-list/numbered-list.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'oiv-viewer-bullet-list',
    standalone: true,
    imports: [
        CommonModule,
        ParagraphComponent,
        HeadingComponent,
        forwardRef(() => BoxComponent),
        forwardRef(() => NumberedListComponent),
    ],
    templateUrl: './bullet-list.component.html',
    styleUrl: './bullet-list.component.scss',
})
export class BulletListComponent {
    readonly helper = inject(Helper);

    @Input() items: Content[] = [];
    @Input() eId = '';

    protected readonly getParagraphCss = getParagraphCss;

    protected readonly OIV_TYPE = OIV_TYPE;
}
