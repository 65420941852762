import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';

import { Content } from '@mobi/oiv-viewer-utils-ng-jslib';
import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

export interface ChapterPageState {
    selectedChapter: Content;
    selectedTopic: Content | undefined;
}

const DEFAULT_STATE: ChapterPageState = {
    selectedChapter: {
        eId: '',
        type: OIV_TYPE.CHAPTER,
        content: [],
    },
    selectedTopic: {
        eId: '',
        type: OIV_TYPE.TOPIC,
        content: [],
    },
};

@Injectable()
export class ChapterPageComponentStore extends ComponentStore<ChapterPageState> {
    constructor() {
        // initial state
        super(DEFAULT_STATE);
    }

    readonly setSelectedChapter = this.updater((state, selectedChapter: Content) => {
        return {
            ...state,
            selectedChapter,
        };
    });

    readonly setSelectedTopic = this.updater((state, topicContent: Content) => {
        return {
            ...state,
            selectedTopic: topicContent,
        };
    });

    readonly getSelectedChapter$ = this.select(state => {
        return state.selectedChapter;
    });
    readonly getSelectedTopic$ = this.select(state => state.selectedTopic);
}
