import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Router, RouterLink } from '@angular/router';

import { MobiUiIconComponent, MobiUiIconsRegistry } from '@mobi/rwc-ui-components-ng-jslib';
import { mobiIconGoToAnchor } from '@mobi/rwc-utils-icons-jslib';

import { TealiumTrackLinkOnsiteDirective } from '@mobi/tea-tagmanager-ng-jslib';

import { ContentItem } from '../../model/atomic-model';
import { ParagraphComponent } from '../paragraph/paragraph.component';
import { Helper } from '../../helper';

@Component({
    selector: 'oiv-viewer-anchor-list',
    standalone: true,
    imports: [CommonModule, MobiUiIconComponent, RouterLink, ParagraphComponent, TealiumTrackLinkOnsiteDirective],
    templateUrl: './anchor-list.component.html',
    styleUrl: './anchor-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnchorListComponent {
    #router = inject(Router);
    helper = inject(Helper);

    anchors = input<ContentItem[]>([]);
    anchorClick = output<string>();
    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconGoToAnchor]);
    }

    onClick(elementId: string): void {
        this.anchorClick.emit(elementId);
    }
}
