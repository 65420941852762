<ul class="mobi-ui-margin-y-sm" [attr.id]="eId">
    <ng-container *ngFor="let item of items">
        <li [attr.id]="item.eId">
            <ng-container *ngFor="let content of helper.getContentAsArray(item)">
                @if (helper.contentIsString(content)) {
                    <ng-container>
                        <oiv-viewer-paragraph
                            [content]="helper.getContentAsString(content)"
                            [eId]="content.eId"
                            [cssClass]="getParagraphCss(content.type)"
                        ></oiv-viewer-paragraph>
                    </ng-container>
                }
                @if (helper.contentIsArray(content)) {
                    <ng-container [ngSwitch]="content.type">
                        <oiv-viewer-bullet-list
                            *ngSwitchCase="OIV_TYPE.BULLET_LIST"
                            [items]="helper.getContentAsArray(content)"
                            [eId]="content.eId"
                        ></oiv-viewer-bullet-list>
                        <oiv-viewer-numbered-list
                            *ngSwitchCase="OIV_TYPE.NUMBERED_LIST"
                            [items]="helper.getContentAsArray(content)"
                            [eId]="content.eId"
                        ></oiv-viewer-numbered-list>
                        <oiv-viewer-box
                            *ngSwitchCase="OIV_TYPE.BOX"
                            [contentArray]="helper.getContentAsArray(content)"
                            [eId]="content.eId"
                        >
                        </oiv-viewer-box>
                    </ng-container>
                }
            </ng-container>
        </li>
    </ng-container>
</ul>
