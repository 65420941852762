import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    NgZone,
    ViewContainerRef,
    inject,
    signal,
    effect,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { take } from 'rxjs';
import { ActivatedRoute, Router, Scroll } from '@angular/router';

import { Content } from '@mobi/oiv-viewer-utils-ng-jslib';
import { CHAPTER_NAME, OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { HeadingComponent } from '../../../share/components/heading/heading.component';
import { ContentItem } from '../../../share/model/atomic-model';
import { ParagraphComponent } from '../../../share/components/paragraph/paragraph.component';
import { AnchorListComponent } from '../../../share/components/anchor-list/anchor-list.component';
import { BulletListComponent } from '../../../share/components/bullet-list/bullet-list.component';
import { getHeadingCss, getParagraphCss } from '../../../util/get-css-style';
import { NumberedListComponent } from '../../../share/components/numbered-list/numbered-list.component';
import { BoxComponent } from '../../../share/components/box/box.component';
import { Helper } from '../../../share/helper';
import { ExpanderComponent } from '../../../share/components/expander/expander.component';
import { GlobalToggleCollapseAllButtonComponent } from '../../../share/components/global-toggle-expandable-button/global-toggle-collapse-all-button.component';

@Component({
    standalone: true,
    selector: 'oiv-viewer-chapter-page',
    templateUrl: './chapter-page.component.html',
    styleUrls: ['./chapter-page.component.scss'],
    imports: [
        CommonModule,
        HeadingComponent,
        ParagraphComponent,
        AnchorListComponent,
        ExpanderComponent,
        BoxComponent,
        GlobalToggleCollapseAllButtonComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChapterPageComponent implements AfterViewInit {
    protected ref = inject(ViewContainerRef);
    protected readonly helper = inject(Helper);
    protected route = inject(ActivatedRoute);
    protected router = inject(Router);
    protected ngZone = inject(NgZone);
    protected linkTarget = '';

    #expanderComponentRefsMap = new Map<string, ComponentRef<ExpanderComponent>>();
    #globalCollapseAllBtn: ComponentRef<GlobalToggleCollapseAllButtonComponent> =
        {} as ComponentRef<GlobalToggleCollapseAllButtonComponent>;
    #expandedStates = signal<{ eID: string; expanded: boolean }[]>([]);
    #hasGeneratedAnchorList = false;
    #isGlossary = false;
    readonly OIV_TYPE = OIV_TYPE;

    constructor() {
        effect(() => {
            const shouldExpandAll = this.#expandedStates().some(item => !item.expanded);
            if (this.#globalCollapseAllBtn?.setInput) {
                this.#globalCollapseAllBtn.setInput('shouldExpandAll', shouldExpandAll);
            }
        });
    }

    ngAfterViewInit(): void {
        this.linkTarget = this.router.url;
        this.route.fragment.pipe(take(1)).subscribe(fragment => {
            if (!fragment) return;
            this.#scrollToElement(fragment);
        });
    }

    renderContent(content: Content, hasNoTopic: boolean) {
        this.#hasGeneratedAnchorList = false;
        if (content.type === OIV_TYPE.CHAPTER) {
            this.#isGlossary = content.chapterGroupName === CHAPTER_NAME.GLOSSARY;
        }
        (content.content as Array<Content>).forEach((item: Content) => {
            const component = this.#getComponent(item, hasNoTopic, content);
            if (!component) {
                return;
            }
        });
    }

    #renderExpander(content: Content) {
        this.#expanderComponentRefsMap.clear();
        this.#globalCollapseAllBtn = this.ref.createComponent(GlobalToggleCollapseAllButtonComponent);
        const exps = (content.content as Array<Content>).filter((item: Content) => item.type === OIV_TYPE.CONTAINER);
        exps.forEach((expanderContent: Content) => {
            const expanderComponentRef = this.ref.createComponent(ExpanderComponent);
            this.#expanderComponentRefsMap.set(expanderContent.eId, expanderComponentRef);
            expanderComponentRef.setInput('expanderInput', expanderContent);
            // listening on state change of the expander then updating the expanded state list
            expanderComponentRef.instance.expandedSingleState.subscribe(() => {
                this.#expandedStates.set(this.#convertMapToArray(this.#expanderComponentRefsMap));
            });
        });

        // Set the initial expanded state of all expanders
        this.#expandedStates.set(this.#convertMapToArray(this.#expanderComponentRefsMap));
        this.#globalCollapseAllBtn.instance.onToggleExpanded.subscribe((state: boolean) => {
            this.#expanderComponentRefsMap.forEach((expComp: ComponentRef<ExpanderComponent>) => {
                expComp.instance.expandedAllState.set(state);
            });
        });
    }

    #convertMapToArray(map: Map<string, ComponentRef<ExpanderComponent>>) {
        const obj = Object.fromEntries(map);
        return Object.entries(obj).map(([key, value]) => ({ eID: key, expanded: value.instance.getExpandedState() }));
    }

    // The anchor list generation is incompatible with other components, requiring a separate function
    #generateAnchorList(content: Content) {
        const anchorList: ContentItem[] = [];
        (content.content as Array<Content>).forEach((item: Content) => {
            if (item.type === OIV_TYPE.CONTAINER && typeof item.content !== 'string') {
                item.content.forEach((subItem: Content) => {
                    if (subItem.type === OIV_TYPE.SECTION_TITLE) {
                        anchorList.push(this.helper.createAnchorListContent(subItem, item.eId));
                    }
                });
            }
        });
        if (anchorList.length > 0) {
            const component = this.ref.createComponent(AnchorListComponent);
            component.setInput('anchors', anchorList);
            component.instance.anchorClick.subscribe((elementId: string) => this.onAnchorSelected(elementId));
        }
    }

    #getComponent(item: Content, hasNoTopic: boolean, wholeContent: Content): ComponentRef<any> | null {
        switch (item.type) {
            case OIV_TYPE.CHAPTER_TITLE: {
                const component = this.ref.createComponent(HeadingComponent);
                const heading = this.helper.createContent<ContentItem>(item);
                component.setInput('heading', heading);
                component.setInput('cssClass', getHeadingCss(item, hasNoTopic));
                return component;
            }
            case OIV_TYPE.TOPIC_TITLE: {
                if (hasNoTopic) {
                    return null;
                }
                const component = this.ref.createComponent(HeadingComponent);
                const heading = this.helper.createContent<ContentItem>(item);
                component.setInput('heading', heading);
                component.setInput('cssClass', getHeadingCss(item));
                return component;
            }
            case OIV_TYPE.INFO:
            case OIV_TYPE.INTRO:
            case OIV_TYPE.PARA: {
                const component = this.ref.createComponent(ParagraphComponent);
                component.setInput('content', item.content);
                component.setInput('eId', item.eId);
                component.setInput('cssClass', getParagraphCss(item.type));
                return component;
            }
            case OIV_TYPE.BULLET_LIST: {
                const component = this.ref.createComponent(BulletListComponent);
                component.setInput('eId', item.eId);
                component.setInput('items', this.helper.getListItems(item));
                return component;
            }
            case OIV_TYPE.NUMBERED_LIST: {
                const component = this.ref.createComponent(NumberedListComponent);
                component.setInput('items', this.helper.getListItems(item));
                component.setInput('eId', item.eId);
                component.setInput('listType', item.attributeType);
                return component;
            }
            case OIV_TYPE.BOX: {
                const component = this.ref.createComponent(BoxComponent);
                component.setInput('contentArray', item.content as Content[]);
                component.setInput('eId', item.eId);
                return component;
            }
            case OIV_TYPE.CONTAINER_LIST: {
                // This is the only case when all the containers are in a wrapper container like KI chapter
                if (!this.#hasGeneratedAnchorList && !this.#isGlossary) {
                    if (!this.#isGlossary) {
                        this.#generateAnchorList(wholeContent); // Keep the order of the anchor list generation correct
                    }
                    this.#renderExpander(item);
                    this.#hasGeneratedAnchorList = true;
                }
                break;
            }
            case OIV_TYPE.CONTAINER: {
                if (!this.#hasGeneratedAnchorList) {
                    if (!this.#isGlossary) {
                        this.#generateAnchorList(wholeContent); // Keep the order of the anchor list generation correct
                    }
                    this.#renderExpander(wholeContent);
                    this.#hasGeneratedAnchorList = true;
                }
                break;
            }
            default:
                return null;
        }
        return null;
    }

    onAnchorSelected(elementId: string): void {
        this.linkTarget = this.linkTarget.concat('#', elementId);
        this.router
            .navigate([], {
                fragment: elementId,
            })
            .then(() => {
                document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth' });
            });
        // Open expander when anchor item is clicked
        const expanderComponentRef = this.#expanderComponentRefsMap.get(elementId);
        if (expanderComponentRef) {
            expanderComponentRef.instance.openExpander();
        }
    }

    scrollFragment(): void {
        this.router.events.subscribe(event => {
            if (!(event instanceof Scroll)) return;
            if (!event.anchor) return;
            this.#scrollToElement(event.anchor);
        });
    }

    #scrollToElement(fragment: string): void {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                const element = document.getElementById(fragment);
                if (!element) return;
                const rootElement = document.querySelector('#avb-content');
                if (!rootElement) return;
                const rootRect = rootElement.getBoundingClientRect();
                const elementRect = element.getBoundingClientRect();
                const elementPosition = elementRect.top - rootRect.top;
                window.scrollTo({ top: elementPosition, behavior: 'smooth' });
            });
        });
    }
}
