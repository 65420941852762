import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    inject,
    Input,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { Content } from '@mobi/oiv-viewer-utils-ng-jslib';

import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { ParagraphComponent } from '../paragraph/paragraph.component';
import { BulletListComponent } from '../bullet-list/bullet-list.component';
import { getParagraphCss } from '../../../util/get-css-style';
import { NumberedListComponent } from '../numbered-list/numbered-list.component';

@Component({
    selector: 'oiv-viewer-box',
    standalone: true,
    imports: [
        CommonModule,
        ParagraphComponent,
        forwardRef(() => BulletListComponent),
        forwardRef(() => NumberedListComponent),
    ],
    templateUrl: './box.component.html',
    styleUrl: './box.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxComponent implements AfterViewInit {
    @Input() contentArray: Content[] = [];
    @Input() eId = '';
    @ViewChild('container', { read: ViewContainerRef, static: true }) ref!: ViewContainerRef;
    cdr = inject(ChangeDetectorRef);

    ngAfterViewInit(): void {
        this.contentArray.forEach(content => this.getComponent(content));
        this.cdr.detectChanges();
    }

    getComponent(content: Content) {
        switch (content.type) {
            case OIV_TYPE.PARA:
            case OIV_TYPE.BOX_TITLE: {
                const component = this.ref.createComponent(ParagraphComponent, {});
                component.setInput('content', content.content);
                component.setInput('eId', content.eId);
                component.setInput('cssClass', getParagraphCss(content.type));
                return component;
            }
            case OIV_TYPE.BULLET_LIST: {
                const component = this.ref.createComponent(BulletListComponent);
                component.setInput('items', content.content);
                component.setInput('eId', content.eId);
                return component;
            }
            case OIV_TYPE.NUMBERED_LIST: {
                const component = this.ref.createComponent(NumberedListComponent);
                component.setInput('eId', content.eId);
                component.setInput('items', content.content);
                component.setInput('listType', content.attributeType);
                return component;
            }
            case OIV_TYPE.BOX: {
                const component = this.ref.createComponent(BoxComponent);
                component.setInput('contentArray', content.content as Content[]);
                component.setInput('eId', content.eId);
                return component;
            }
            default:
                return;
        }
    }
}
